import React, { useContext } from 'react'
import { useRouter } from 'next/router'
import Head from 'next/head'
import { SiteContentContext } from 'contexts'

function Meta() {
  const {
    host,
    title,
    share_title,
    share_text,
    share_tweet,
    share_image,
    meta: { keywords },
  } = useContext(SiteContentContext)

  const { asPath } = useRouter()

  const SITE_NAME = title
  const url = `${host}${asPath}`
  const imageSrc = share_image ? `${host}${share_image}` : ''

  return (
    <Head>
      <title>{SITE_NAME}</title>
      <meta name="keywords" content={keywords} />
      <meta property="og:type" content="website" />
      <meta name="title" content={share_title} />
      <meta name="description" content={share_text} />
      <meta property="og:site_name" content={SITE_NAME} />
      <meta property="og:title" content={share_title} />
      <meta property="og:description" content={share_text} />
      <meta property="og:image" content={imageSrc} />
      <meta property="og:url" content={url} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={share_title} />
      <meta name="twitter:text:title" content={share_title} />
      <meta name="twitter:description" content={share_tweet} />
      <meta name="twitter:image" content={imageSrc} />
      <meta name="twitter:url" content={url} />
      <meta property="og:image:width" content="450" />
      <meta property="og:image:height" content="298" />
      <link rel="image_src" href={imageSrc} />
    </Head>
  )
}

export default Meta
