import React from 'react'
import styled from 'styled-components'
import { spacings } from 'theme'
interface Props {
  className?: string
  children: React.ReactNode
}

const TitleWithBorder = ({ className, children }: Props) => (
  <h2 className={className}>{children}</h2>
)

const StyledTitleWithBorder = styled(TitleWithBorder)`
  border-bottom: 3px solid ${({ theme }) => theme.colors.dark};
  ${spacings('margin-bottom', 'm')};
  ${spacings('padding-bottom', 's')};

  @media (min-width: 768px) {
    border-width: 4px;
  }
`

export default StyledTitleWithBorder
