import React, { useMemo, useState } from 'react'
import styled from 'styled-components'
import { formatPublicDate } from 'fbk-utils'
import { Tooltip, Modal } from 'components'
import { Event as IEvent } from 'types'
import useScreenSize from 'hooks/use-screen-size'
import { formatLocalDateToUTC } from 'utils/helpers'

const Wrapper = styled.div`
  margin-top: 28px;

  :nth-child(2) {
    margin-top: 20px;
  }

  .label {
    color: ${({ theme }) => theme.colors.grey};

    &-line {
      width: 100%;
      height: 1px;
      margin: 14px 16px 0;
      background: ${({ theme }) => theme.colors.grey};
    }
  }

  .tooltip {
    position: relative;
    z-index: 9999;
    text-decoration-line: underline;
    cursor: pointer;
    transition: 0.2s ease;
  }

  .event-btn {
    text-align: left;
  }

  @media (max-width: 767px) {
    .label-line {
      display: none;
    }
  }

  @media (min-width: 768px) {
    display: flex;

    .label {
      display: flex;
      min-width: 204px;
      color: #000;
      white-space: nowrap;
    }

    .tooltip {
      :hover {
        color: ${({ theme }) => theme.colors.primary};
        /* text-decoration-color: ${({ theme }) => theme.colors.primary}; */
      }
    }
  }

  @media (min-width: 1200px) {
    margin-top: 20px;
  }
`

const Event = ({
  comment,
  date,
  description: { rus, eng, description },
}: IEvent) => {
  const [modalIsOpen, setModal] = useState(false)

  const { isMediumScreen } = useScreenSize()

  const event = useMemo(() => {
    if ((comment || description) && isMediumScreen)
      return (
        <button onClick={() => setModal(true)} className="event-btn">
          {rus || eng}
        </button>
      )
    else if ((comment || description) && !isMediumScreen)
      return (
        <Tooltip content={<p>{comment || description}</p>}>
          {rus || eng}
        </Tooltip>
      )
    else return rus || eng
  }, [comment, description, eng, isMediumScreen, rus])

  const { date: dayAndMonth, year } = formatPublicDate(
    formatLocalDateToUTC(date),
    {
      isFull: true,
      hideСurrentYear: false,
    },
  )

  return (
    <Wrapper>
      <p className="label">
        {dayAndMonth} {year}
        <span className="label-line" />
      </p>
      {event}
      <Modal
        isFullScreen
        positionY="bottom"
        isOpen={modalIsOpen}
        onClose={() => setModal(false)}
        title={comment || description}
      />
    </Wrapper>
  )
}

export default Event
