import React, { useContext } from 'react'
import { Sharing as SharingFbk } from 'fbk-components'
import { SiteContentContext } from 'contexts'
import { useRouter } from 'next/router'

interface Props {
  socials: {
    type:
      | 'vk'
      | 'facebook'
      | 'twitter'
      | 'telegram'
      | 'ok'
      | 'whatsApp'
      | 'viber'
    icon?: string
    IconComponent?: React.FC
    backgroundColor?: string
  }[]
  className?: string
}

const Sharing = ({ socials, className }: Props) => {
  const { share_title, share_text, share_tweet, host } = useContext(
    SiteContentContext,
  )

  const { asPath } = useRouter()

  return (
    <SharingFbk
      className={className}
      title={share_title}
      text={share_text}
      twitter={share_tweet}
      url={`${host}${asPath}`}
      socials={socials}
    />
  )
}

export default Sharing
