import React from 'react'
import styled from 'styled-components'
import { Input, Button } from 'components'
import { Bell } from 'assets/svg'

const Title = styled.p`
  display: flex;
  margin-bottom: 16px;
  font-family: ${({ theme }) => theme.fonts.main};
  font-size: 20px;
  line-height: 24px;

  .bell-icon {
    margin-right: 8px;
    color: #000;
  }
`

const InputWrap = styled.div`
  position: relative;
  max-width: 720px;

  .button-mob {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 32px;
  }

  .button {
    position: absolute;
    right: 0;
    width: 216px !important;
    margin-top: 0 !important;
    color: ${({ theme }) => theme.colors.primary};

    .path {
      stroke: ${({ theme }) => theme.colors.primary};
    }
  }

  @media (min-width: 768px) {
    display: flex;

    .button-mob {
      display: none;
    }
  }

  @media (min-width: 992px) {
    .button {
      :hover {
        filter: brightness(60%);
      }

      :active {
        color: ${({ theme }) => theme.colors.primaryPressed};
      }
    }
  }
`

const StyledInput = styled(Input)`
  width: 100%;

  @media (min-width: 768px) {
    .input {
      padding-right: 206px;

      :focus {
        padding-right: 206px;
      }
    }
  }
`

interface Props {
  isLoading?: boolean
}

const CustomInput = ({ isLoading }: Props) => {
  const buttonProps = {
    type: 'submit',
    isLoader: isLoading,
    loaderText: 'Подождите...',
  }

  return (
    <>
      <Title>
        <Bell className="bell-icon g-hide-on-mobile" />
        Подпишитесь на&nbsp;обновления по&nbsp;делу
      </Title>
      <InputWrap>
        <StyledInput name="email" placeholder="Ваша электронная почта" />
        <Button className="button g-hide-on-mobile" {...buttonProps}>
          Подписаться
        </Button>
        <Button color="accent" className="button-mob" {...buttonProps}>
          <Bell className="bell-icon" />
          Подписаться
        </Button>
      </InputWrap>
    </>
  )
}

export default CustomInput
