import React from 'react'
import styled, { css } from 'styled-components'
import { Button as ButtonFbk } from 'fbk-components'

interface Props {
  children: React.ReactNode
  className?: string
  isLight?: true
  isGrey?: true
  [key: string]: any
}

const Button = ({ children, className, isLight, isGrey, ...rest }: Props) => (
  <ButtonFbk className={className} {...rest} size="large">
    {children}
  </ButtonFbk>
)

const StyledButton = styled(Button)`
  padding: 0;
  font-size: 20px;
  font-weight: 500;
  line-height: 20px;
  ${({ isLight }: { isLight?: boolean }) =>
    isLight &&
    css`
      border: 2px solid #000;

      @media (min-width: 992px) {
        :hover {
          color: #fff;
          background: ${({ theme }) => theme.colors.primary};
          border-color: ${({ theme }) => theme.colors.primary};
        }

        :active {
          color: #fff;
          background: ${({ theme }) => theme.colors.primaryPressed};
          border-color: ${({ theme }) => theme.colors.primaryPressed};
        }
      }
    `}
  ${({ isGrey }: { isGrey?: boolean }) =>
    isGrey &&
    css`
      background: ${({ theme }) => theme.colors.greyBg};

      svg .path {
        stroke: #000;
      }

      @media (min-width: 992px) {
        :hover {
          color: #fff;
          background: ${({ theme }) => theme.colors.primary};
        }

        :active,
        :disabled {
          color: #fff;
          background: ${({ theme }) => theme.colors.primaryPressed};

          svg .path {
            stroke: #fff;
          }
        }
      }
    `}
`

export default StyledButton
