import React from 'react'
import styled from 'styled-components'

interface Props {
  children?: React.ReactNode
  className?: string
  isLight?: boolean
}

const Text = ({ children, isLight, className }: Props) => (
  <p className={`${className}${isLight && ' g-light-text'}`}>{children}</p>
)

const StyledText = styled(Text)``

export default StyledText
