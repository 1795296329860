import { API_URL } from './env'

if (!process.browser)
  console.log(
    `From /constants/env.ts: ${API_URL}; From process.env: ${process.env.API_URL}`,
  )

export const SITE_CONTENT = `${API_URL}/content/index/`

export const NEWS = `${API_URL}/content/news/`

export const CASES = `${API_URL}/case/search/`

export const CASE = `${API_URL}/case/`

export const CASE_EVENTS = `${API_URL}/case/events/`

export const CASE_SUBSCRIBE = `${API_URL}/subscription/new`

export const SUGGEST_PERSON = `${API_URL}/suggest/person`

export const SUGGEST_REPRESENTATIVE = `${API_URL}/suggest/representative`

export const CONFIRM_EMAIL = `${API_URL}/subscription/confirm`

export const UNSUBSCRIBE = `${API_URL}/subscription/unsubscribe`
