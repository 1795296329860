import React from 'react'
import { useField } from 'formik'
import styled from 'styled-components'
import { Select as FbkSelect } from 'fbk-components'
import { AutocompleteItem } from 'types'
import { ArrowTop, ArrowDown } from 'assets/svg'

const StyledSelect = styled(FbkSelect)`
  .input {
    font-size: 18px;
    border-color: ${({ theme }) => theme.colors.greyLine};
    transition: none;
  }

  .label {
    font-size: 15px;
  }

  .placeholder {
    font-size: 18px;
    color: ${({ theme }) => theme.colors.grey};
  }

  .list li {
    font-size: 18px;

    :hover {
      background: ${({ theme }) => theme.colors.greyBg};
    }
  }
`

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string
  label: string
  countReset?: number
  items: AutocompleteItem[]
}

const Select = ({ name, items, countReset, ...rest }: Props) => {
  const [field, meta, { setValue }] = useField({ name })

  const initialValue = field.value ? `20${field.value}` : ''

  return (
    <StyledSelect
      styleType="lightGrey"
      listMaxHeight={240}
      items={items}
      isBig
      countReset={countReset}
      initialValue={initialValue}
      error={meta.touched && meta.error}
      selectItem={(selectItem: AutocompleteItem | undefined) => {
        if (selectItem) {
          setValue(selectItem.value)
        }
      }}
      OpenIcon={<ArrowTop />}
      CloseIcon={<ArrowDown />}
      {...field}
      {...rest}
    />
  )
}

export default Select
