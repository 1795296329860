import React, { createContext, ReactNode } from 'react'
import { SiteContent, NewsArticle } from 'types'

const SiteContentContext = createContext<any>({})

interface Props {
  host: string
  data: SiteContent | NewsArticle
  children: ReactNode
}

export const SiteContentProvider = ({ data, host, children }: Props) => {
  return (
    <SiteContentContext.Provider value={{ ...data, host }}>
      {children}
    </SiteContentContext.Provider>
  )
}

export default SiteContentContext
