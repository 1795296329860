import React, { createContext, ReactNode } from 'react'

interface Page {
  isLoading: boolean
}

const PageContext = createContext<Page>({
  isLoading: false,
})

interface Props extends Page {
  children: ReactNode
}

export const PageProvider = ({ isLoading, children }: Props) => {
  return (
    <PageContext.Provider value={{ isLoading }}>
      {children}
    </PageContext.Provider>
  )
}

export default PageContext
