import { getYear, isThisYear, format } from 'date-fns'
import { ru } from 'date-fns/locale'
import fromEntries from 'object.fromentries'

const localeOptions = {
  locale: ru,
}

export const createQueryParams = (params: any): string =>
  Object.keys(params)
    .map(
      (param: string, index: number) =>
        `${index ? '&' : '?'}${param}=${params[param]}`,
    )
    .join('')

export function parseApiErrors(
  json?: any,
  defaultError: string = 'Не удалось отправить форму. Пожалуйста повторите попытку позже',
): string {
  if (typeof json === 'object') {
    const errorsArray = Object.keys(json).map(key => json[key])

    if (!Array.isArray(errorsArray)) return defaultError
    const [error] = errorsArray

    if (!Array.isArray(error)) return defaultError
    const [errorObject] = error

    if (typeof errorObject !== 'object') return defaultError

    const message = Object.values(errorObject)[0]

    if (typeof message === 'string') return message
    return defaultError
  }

  return defaultError
}

export const filterObjectByValues = (object: { [key in string]: any }) =>
  fromEntries(Object.entries(object).filter(([_, value]) => !!value))

export const getNewsMonth = () => {
  const INITIAL_VALUES = {
    value: '',
    wasShown: false,
  }

  let monthTitle = INITIAL_VALUES

  return (date: Date | string) => {
    const dateParse = new Date(date)
    const publicDate = `${format(dateParse, 'LLLL', localeOptions)}${
      isThisYear(dateParse) ? '' : `, ${getYear(dateParse)}`
    }`

    if (monthTitle.value !== publicDate) {
      monthTitle.value = publicDate
      monthTitle.wasShown = false
    } else {
      monthTitle.wasShown = true
    }

    return monthTitle
  }
}

export const formatLocalDateToUTC = (dateValue: string) => {
  const date = new Date(dateValue)
  const userTimezoneOffset = date.getTimezoneOffset() * 60000

  return date.getTime() + userTimezoneOffset
}
