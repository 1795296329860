import useWindowSize from './use-window-size'

const useScreenSize = () => {
  const mobileBreakPoint = 768
  const TabletBreakPoint = 992
  const desktopBreakPoint = 1200

  const { width } = useWindowSize()

  const isSmallScreen = typeof width === 'number' && width < mobileBreakPoint
  const isMediumScreen = typeof width === 'number' && width < TabletBreakPoint
  const isLargeScreen = typeof width === 'number' && width >= desktopBreakPoint

  return {
    isSmallScreen,
    isMediumScreen,
    isLargeScreen,
  }
}

export default useScreenSize
