import React from 'react'
import styled from 'styled-components'
import { Tooltip as FbkTooltip } from 'fbk-components'

const TooltipContent = styled.div`
  position: relative;
  bottom: 16px;
  display: none;
  width: 460px;
  padding: 24px;
  background: #fff;
  box-shadow: 0 2px 32px rgba(0, 0, 0, 0.1);

  @media (min-width: 992px) {
    display: block;
  }
`

interface Props {
  children: React.ReactNode
  content: React.ReactNode
  position?: string
}

const Tooltip = ({ children, content, position = 'top-start' }: Props) => {
  return (
    <FbkTooltip
      unmountHTMLWhenHide
      position={position}
      className="tooltip"
      html={<TooltipContent>{content}</TooltipContent>}
    >
      {children}
    </FbkTooltip>
  )
}

export default Tooltip
