import React, { useState } from 'react'
import styled from 'styled-components'
import { Tooltip, Modal, CaseSubscribeForm, Button } from 'components'
import * as Icons from 'assets/svg'
import { CaseDescription } from 'types'
import useScreenSize from 'hooks/use-screen-size'
import { formatPublicDate } from 'fbk-utils'
import { formatLocalDateToUTC } from 'utils/helpers'

const Wrapper = styled.div`
  margin-top: 28px;

  @media (min-width: 1200px) {
    width: 400px;
    margin-top: 0;
  }
`

const Info = styled.div`
  padding: 16px;
  background: ${({ theme }) => theme.colors.greyBg};

  .row {
    display: flex;
    justify-content: space-between;

    :first-child {
      margin-bottom: 6px;
    }
  }

  .info-icon,
  .tooltip-icon {
    color: ${({ theme }) => theme.colors.grey};
  }

  .status-btn {
    text-align: left;
  }

  @media (min-width: 768px) {
    .info-icon {
      display: none;
    }
  }

  @media (min-width: 992px) {
    .tooltip-icon {
      cursor: pointer;

      :hover {
        color: ${({ theme }) => theme.colors.primary};
      }
    }
  }

  @media (min-width: 1200px) {
    min-height: 120px;
  }
`

const Subscribe = styled.div`
  z-index: 9999;
  display: flex;
  align-items: center;
  height: 60px;
  padding: 16px;
  cursor: pointer;
  background: ${({ theme }) => theme.colors.greyBg};
  border-top: 1px solid ${({ theme }) => theme.colors.greyLine};
  transition: 0.2s ease;

  .btn {
    font-family: ${({ theme }) => theme.fonts.additional};
    text-decoration-line: underline;
  }

  .bell-icon {
    margin-right: 8px;
  }

  @media (min-width: 992px) {
    :hover {
      color: ${({ theme }) => theme.colors.primary};

      .btn {
        color: ${({ theme }) => theme.colors.primary};
      }
    }
  }
`

interface Props extends CaseDescription {
  isOnlyOne: boolean
  id: number
  finished: boolean
}

const Status = ({
  isOnlyOne,
  finished,
  id,
  rus,
  eng,
  description,
  date,
}: Props) => {
  const [statusModalIsOpen, setStatusModal] = useState(false)
  const [successModalIsOpen, setSuccessModal] = useState(false)
  const [subscribeModalIsOpen, setSubscribeModal] = useState(false)
  const [successText, setSuccessText] = useState('')

  const { isMediumScreen } = useScreenSize()

  const handleSubscribeSuccess = (response: {
    title: string
    text: string
  }) => {
    setSuccessText(response.text)
    setSubscribeModal(false)
    setSuccessModal(true)
  }

  const { date: dayAndMonth, year } = formatPublicDate(
    formatLocalDateToUTC(date),
    {
      isFull: true,
      hideСurrentYear: false,
    },
  )

  return (
    <Wrapper>
      <Info>
        <div className="row">
          <p>{rus || eng}</p>
          {description &&
            (isMediumScreen ? (
              <button
                onClick={() => setStatusModal(true)}
                className="status-btn"
              >
                <Icons.DetailInfo className="g-hide-on-mobile tooltip-icon" />
              </button>
            ) : (
              <Tooltip position="top-end" content={<p>{description}</p>}>
                <Icons.DetailInfo className="g-hide-on-mobile tooltip-icon" />
              </Tooltip>
            ))}
        </div>
        <div className="row">
          <p className="g-light-text g-small-text">
            {dayAndMonth} {year}
          </p>
          {description && (
            <button onClick={() => setStatusModal(true)} className="status-btn">
              <Icons.DetailInfo className="info-icon" />
            </button>
          )}
        </div>
      </Info>
      {!isOnlyOne && !finished && (
        <Subscribe>
          <Icons.Bell className="bell-icon" />
          <button className="btn" onClick={() => setSubscribeModal(true)}>
            Подписаться<span className="g-hide-on-mobile"> на оповещения</span>
          </button>
        </Subscribe>
      )}
      <Modal
        isFullScreen
        positionY="bottom"
        isOpen={statusModalIsOpen}
        onClose={() => setStatusModal(false)}
        title={description}
      />
      <Modal
        title={<>Подпишитесь на&nbsp;обновления по&nbsp;делу</>}
        isOpen={subscribeModalIsOpen}
        onClose={() => setSubscribeModal(false)}
      >
        <CaseSubscribeForm id={id} onSuccess={handleSubscribeSuccess} />
      </Modal>
      <Modal
        title={
          <>
            <span className="g-red-text">Подтвердите почту,</span> чтобы
            получать обновления по&nbsp;делу
          </>
        }
        isOpen={successModalIsOpen}
        onClose={() => setSuccessModal(false)}
      >
        <div className="success-content">
          <div dangerouslySetInnerHTML={{ __html: successText }} />
          <Button isLight onClick={() => setSuccessModal(false)}>
            Закрыть
          </Button>
        </div>
      </Modal>
    </Wrapper>
  )
}

export default Status
