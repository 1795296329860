import React from 'react'
import styled, { css } from 'styled-components'
import { Section } from 'fbk-components'
import * as Icons from 'assets/svg'
import { spacings } from 'theme'
import TitleWithBorder from './TitleWithBorder'
import Sharing from './Sharing'

const getCommonBorderStyle = () => css`
  width: calc(100% - 32px);
  margin: 0 auto;
  ${spacings('margin-top', 'xl')}

  @media (min-width: 768px) {
    width: ${({ theme }) => theme.containerWidth.tablet};
  }

  @media (min-width: 1200px) {
    width: ${({ theme }) => theme.containerWidth.desktop};
  }
`

const StyledTitleWithBorder = styled(TitleWithBorder)`
  padding-bottom: 40px;
  ${getCommonBorderStyle()}

  > p {
    max-width: 560px;
  }

  @media (min-width: 768px) {
    padding-bottom: 32px;
  }
`

const BorderTop = styled.div`
  height: 3px;
  min-height: 3px;
  background: #000;
  ${getCommonBorderStyle()}
`

const Wrapper = styled(Section as any)`
  font-family: ${({ theme }) => theme.fonts.main};

  .copyright {
    margin-top: 24px;
    color: ${({ theme }) => theme.colors.grey};
  }

  @media (min-width: 768px) {
    .sharing-wrap {
      display: flex;
    }

    .copyright {
      margin-top: 12px;
    }
  }

  @media (min-width: 1200px) {
    display: flex;
    justify-content: space-between;

    .copyright {
      margin-top: 0;
      color: #000;
    }
  }
`

const StyledSharing = styled(Sharing)`
  margin-top: 8px;

  li {
    :not(:last-child) {
      ${spacings('margin-right', 'xs')}
    }

    a {
      color: #000;
    }

    :hover a {
      opacity: 0.8;
    }
  }

  @media (min-width: 768px) {
    margin-top: 0;
    margin-left: ${({ theme }) => theme.space.tablet.m};
  }
`

interface Props {
  email?: string
}

const Footer = ({ email }: Props) => {
  const currentYear = new Date().getFullYear()

  return (
    <>
      {email ? (
        <StyledTitleWithBorder>
          <p>
            По&nbsp;всем вопросам пишите на&nbsp;
            <a className="g-red-text" href={`mailto:${email}`}>
              {email}
            </a>
          </p>
        </StyledTitleWithBorder>
      ) : (
        <BorderTop />
      )}
      <Wrapper as="footer" space="l" className="g-big-text">
        <div className="sharing-wrap">
          Расскажите о проекте<span className="g-hide-on-mobile">:</span>
          <StyledSharing
            socials={[
              { type: 'telegram', IconComponent: Icons.Telegram },
              { type: 'twitter', IconComponent: Icons.Twitter },
              { type: 'vk', IconComponent: Icons.Vk },
              { type: 'facebook', IconComponent: Icons.Facebook },
              { type: 'ok', IconComponent: Icons.Ok },
            ]}
          />
        </div>
        <p className="copyright">ЕСПЧ.ПОИСК {currentYear}</p>
      </Wrapper>
    </>
  )
}

export default Footer
