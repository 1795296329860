import React, { useContext, useEffect, useState } from 'react'
import { useField } from 'formik'
import styled from 'styled-components'
import { useDebouncedCallback } from 'use-debounce'
import { Autocomplete } from 'fbk-components'
import { AutocompleteItem } from 'types'
import { SearchFormContext } from 'contexts'

const StyledAutocomplete = styled(Autocomplete)`
  .input {
    font-size: 18px;
    border-color: ${({ theme }) => theme.colors.greyLine};
  }

  .input-error {
    border-color: ${({ theme }) => theme.colors.primary};
  }

  .label {
    font-size: 15px;
  }

  .list li {
    font-size: 18px;
  }
`

interface Props {
  name: string
  label: string
  url: string
  placeholder?: string
  type?: string
}

const AsyncAutocomplete = ({ name, url, ...rest }: Props) => {
  const [field, meta, { setValue }] = useField({ name })
  const [items, setItems] = useState([])
  const [inputValue, setInputValue] = useState<string | AutocompleteItem>(
    field.value,
  )

  const { countReset } = useContext(SearchFormContext)

  useEffect(() => {
    countReset && setInputValue('')
  }, [countReset])

  const request = async (value: string) => {
    if (!value) {
      setItems([])
      return
    }

    try {
      const response: any = await fetch(`${url}/?query=${value.trim()}`)
      const data = await response.json()

      if (!response.ok) {
        throw new Error(`Ошибка ${response.status}: Не удалось получить данные`)
      } else {
        const values = data.map((item: AutocompleteItem) => ({
          label: item,
          value: item,
        }))
        setItems(values)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const [requestFiasco] = useDebouncedCallback((value: string) => {
    request(value)
  }, 250)

  const handleSelectItem = (item: AutocompleteItem) => {
    if (item) {
      setValue(item.value)
      setInputValue(item)
    }
  }

  const onChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget

    setValue(value)
    setInputValue(value)

    if (value.length >= 3) {
      requestFiasco(value)
    }
  }

  return (
    <StyledAutocomplete
      styleType="lightGrey"
      id={name}
      isBig
      listMaxHeight={240}
      withoutFilters
      items={items}
      error={meta.touched && meta.error}
      inputValue={inputValue}
      selectItem={handleSelectItem}
      {...field}
      {...rest}
      onChange={onChange}
    />
  )
}

export default AsyncAutocomplete
