import { createGlobalStyle } from 'styled-components'
import { theme } from 'theme'

export const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    padding: 0;
    margin: 0;

    &::before,
    ::after {
      box-sizing: border-box;
    }

    :focus:not(.focus-visible) {
      outline: none;
    }

    input.focus-visible {
      outline: none;
    }
  }

  html {
    height: 100%;
    overflow: visible !important;
    font-family: ${theme.fonts.additional};
    font-size: 18px;
    line-height: 26px;
  }

  body {
    height: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
  }

  #__next {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: ${theme.fonts.main};
    font-weight: normal;
  }

  h2 {
    font-size: 30px;
    line-height: 34px;

    @media (min-width: 768px) {
      font-size: 44px;
      line-height: 48px;
    }
  }

  h3 {
    font-size: 24px;
    line-height: 28px;

    @media (min-width: 768px) {
      font-size: 32px;
      line-height: 36px;
    }
  }

  h4 {
    font-size: 24px;
    line-height: 28px;

    @media (min-width: 768px) {
      font-size: 26px;
      line-height: 32px;
    }
  }

  ul {
    list-style-type: none;
  }

  button {
    font-size: 18px;
    font-weight: normal;
    line-height: 26px;
    background: transparent;
    border: none;
    transition: 0.2 ease;

    * {
      font-family: inherit;
    }
  }

  button,
  a {
    cursor: pointer;
    transition: 0.2s ease;
  }

  img {
    max-width: 100%;
  }

  p a {
    color: inherit;
  }

  /* Remove inset shadow and radius in Safari */
  input,
  textarea {
    border-radius: 0;
    appearance: none;
  }

  /* Remove red shadow invalid field in Firefox */
  input,
  textarea:invalid,
  &:-moz-submit-invalid,
  &:-moz-ui-invalid,
  :not(output):-moz-ui-invalid:-moz-focusring {
    box-shadow: none !important;
  }

  [aria-hidden=true] {
    z-index: -1 !important;
    display: none;
    transition: none !important;
  }

  svg {
    width: 24px;
    height: 24px;
  }

  /* Global classes */
  .g-big-text {
    font-family: ${theme.fonts.main};
    font-size: 20px;
    line-height: 24px;
  }

  .g-light-text {
    color: ${theme.colors.grey};
  }

  .g-small-text {
    font-family: ${theme.fonts.small};
    font-size: 18px;
    font-weight: normal;
    line-height: 24px;
  }

  .g-red-text {
    color: ${theme.colors.primary};

    @media (min-width: 992px) {
      :hover {
        filter: brightness(60%);
      }
    }
  }

  .g-hide-on-mobile {
    @media (max-width: 767px) {
      display: none !important;
    }
  }

  .g-error-text {
    color: ${theme.colors.primary};
  }
`
