import React from 'react'
import { ThemeProvider } from 'styled-components'
import { PageProvider } from 'contexts'
import { theme } from 'theme'
import Loader from '../Loader'
import Meta from './Meta'
import { GlobalStyle } from './global-style'

interface Props {
  children: React.ReactNode
  isLoading: boolean
}

function Page({ isLoading, children }: Props) {
  return (
    <ThemeProvider theme={theme}>
      <PageProvider isLoading={isLoading}>
        <Meta />
        {isLoading && <Loader />}
        {children}
        <GlobalStyle />
      </PageProvider>
    </ThemeProvider>
  )
}

export default Page
