import Telegram from './telegram.svg?sprite'
import Twitter from './twitter.svg?sprite'
import Vk from './vk.svg?sprite'
import Facebook from './facebook.svg?sprite'
import Ok from './ok.svg?sprite'
import TelegramWhite from './telegram-white.svg?sprite'
import TwitterWhite from './twitter-white.svg?sprite'
import VkWhite from './vk-white.svg?sprite'
import FacebookWhite from './facebook-white.svg?sprite'
import OkWhite from './ok-white.svg?sprite'
import Copy from './copy.svg?sprite'
import ArrowRight from './arrow-right.svg?sprite'
import ArrowLeft from './arrow-left.svg?sprite'
import DetailInfo from './detail-info.svg?sprite'
import Bell from './bell.svg?sprite'
import Close from './close.svg?sprite'
import Quote from './quote.svg?sprite'
import Lens from './lens.svg?sprite'
import ArrowSlide from './arrow-slide.svg?sprite'
import Sharing from './sharing.svg?sprite'
import Logo from './logo.svg?sprite'
import LogoMini from './logo-mini.svg?sprite'
import ArrowTop from './arrow-top.svg?sprite'
import ArrowDown from './arrow-down.svg?sprite'

export {
  Telegram,
  Twitter,
  Vk,
  Facebook,
  Ok,
  TelegramWhite,
  TwitterWhite,
  VkWhite,
  FacebookWhite,
  OkWhite,
  Copy,
  ArrowRight,
  ArrowLeft,
  DetailInfo,
  Bell,
  Close,
  Quote,
  Lens,
  ArrowSlide,
  Sharing,
  Logo,
  LogoMini,
  ArrowTop,
  ArrowDown,
}
