import React from 'react'
import Link from 'next/link'
import Head from 'next/head'
import styled from 'styled-components'
import { Section } from 'fbk-components'
import { spacings } from 'theme'
import { HOME } from 'constants/routes'
import { LogoMini } from 'assets/svg'
import TitleWithBorder from './TitleWithBorder'

interface Props {
  statusCode?: number
}

const StyledTitleWithBorder = styled(TitleWithBorder)`
  font-size: 48px;
  line-height: 48px;
  ${spacings('margin-bottom', '2xs')};
  ${spacings('padding-bottom', '2xs')};

  @media (min-width: 768px) {
    font-size: 80px;
    line-height: 80px;
  }
`

const Wrapper = styled(Section)`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-top: 40px;
  padding-bottom: 40px;

  .logo {
    width: 205px;
    height: 28px;
  }

  .btn {
    text-decoration: underline;
  }

  @media (min-width: 768px) {
    max-width: 600px;
  }

  @media (min-width: 992px) {
    .btn:hover,
    .link:hover {
      color: ${({ theme }) => theme.colors.primaryHover};
    }
  }
`

const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
`

const ErrorBlock = ({ statusCode }: Props) => {
  const renderErrorDesc = () => {
    switch (statusCode) {
      case 404:
        return (
          <>
            <p>Этой страницы не&nbsp;существует (как честных единороссов).</p>
            <Link {...HOME}>
              <a className="g-red-text link">Перейти на&nbsp;главную</a>
            </Link>
          </>
        )
      default:
        return (
          <p>
            Что-то пошло не&nbsp;так. Мы&nbsp;уже в&nbsp;курсе и&nbsp;скоро всё
            починим.
            <br />
            <button
              className="g-red-text btn"
              onClick={() => {
                window.location.reload()
              }}
            >
              Обновить страницу
            </button>
          </p>
        )
    }
  }

  return (
    <>
      <Head>
        <title>Ошибка {statusCode}</title>
      </Head>
      <Wrapper space="m">
        <Link {...HOME}>
          <LogoMini className="logo" />
        </Link>
        <Content>
          <div className="inner">
            <StyledTitleWithBorder as="h1">
              Ошибка <span className="g-red-text">{statusCode}</span>
            </StyledTitleWithBorder>
            {renderErrorDesc()}
          </div>
        </Content>
      </Wrapper>
    </>
  )
}

export default ErrorBlock
