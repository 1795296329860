export const HOME = {
  href: '/',
  as: '/',
}

export const NEWS_LIST = {
  href: '/news',
  as: `/news`,
}

export const NEWS_ARTICLE = (id: number) => ({
  href: '/news/[id]',
  as: `/news/${id}`,
})
