import React from 'react'
import styled from 'styled-components'
import { Modal as FbkModal } from 'fbk-components'
import { spacings } from 'theme'
import { Close } from 'assets/svg'
import Button from './Button'

interface Props {
  isOpen: boolean
  onClose: () => void
  positionY?: 'top' | 'center' | 'bottom'
  isFullScreen?: boolean
  children?: React.ReactNode
  title?: React.ReactChild
  className?: string
}

const Modal = ({
  title,
  isOpen,
  children,
  positionY,
  className,
  isFullScreen,
  onClose,
}: Props) => {
  return (
    <FbkModal
      isFullScreen={isFullScreen}
      className={className}
      isOpen={isOpen}
      onClose={onClose}
      positionX="center"
      positionY={positionY}
      modalId="modal"
    >
      {({ onClose }) => (
        <>
          <button className="close" onClick={onClose}>
            <Close />
          </button>
          <h2 className="title">{title}</h2>
          {children && children}
        </>
      )}
    </FbkModal>
  )
}

const Styled = styled(Modal)`
  z-index: ${props => props.theme.zIndex.modal};

  #modal {
    position: relative;
    display: flex;
    flex-flow: column;
    width: 100%;
    min-height: ${({ positionY }) => (positionY ? 'auto' : '100%')};
    ${spacings('padding', 'm')}
  }

  .close {
    position: absolute;
    ${spacings('top', 'm')}
    ${spacings('right', 'm')}
  }

  .title {
    margin-top: 44px;
    margin-bottom: 32px;
  }

  @media (min-width: 768px) {
    background: rgba(0, 0, 0, 0.6);

    #modal {
      width: ${({ isFullScreen }) => (isFullScreen ? '100%' : '640px')};
      min-height: auto;
    }

    .title {
      max-width: 524px;
      margin-top: 0;
      margin-bottom: ${({ children }) => (children ? '32px' : 0)};
    }
  }

  .success-content {
    display: flex;
    flex: 1;
    flex-flow: column;
    align-items: flex-start;
    justify-content: space-between;

    ${Button} {
      @media (min-width: 768px) {
        display: none;
      }
    }
  }
`

export default Styled
