import { fetchJSON as fetchJSONUtil } from 'fbk-utils'
import { USE_AUTHORIZATION } from 'constants/env'
import { authorization } from 'constants/authorization'
import * as endpoints from 'constants/endpoints'
import { createQueryParams } from 'utils/helpers'

const fetchJSON = fetchJSONUtil(USE_AUTHORIZATION ? authorization : undefined)

export const getSiteContent = () => fetchJSON(endpoints.SITE_CONTENT)

interface NewsListParams {
  page?: number
  limit?: number
  exclude?: string
}

export const getNewsList = ({
  page = 1,
  limit = 4,
  exclude,
}: NewsListParams) => {
  let offset = (page - 1) * limit

  return fetchJSON(
    `${endpoints.NEWS}?limit=${limit}&offset=${offset}${
      exclude ? `&exclude=${exclude}` : ''
    }`,
  )
}

export const getNewsArticle = (id: string) =>
  fetchJSON(`${endpoints.NEWS}${id}/`)

export const getCase = (number: string) =>
  fetchJSON(`${endpoints.CASE}${number}`)

export const getCaseEvents = (id: number) =>
  fetchJSON(`${endpoints.CASE_EVENTS}${id}`)

export const getCaseData = ({
  url,
  params,
}: {
  url?: string | null
  params?: { [key in string]: any }
}) =>
  fetchJSON(
    url ||
      `${endpoints.CASES}${encodeURI(
        createQueryParams(params),
      )}&limit=20&offset=0`,
  )

interface CaseSubscribeBody {
  email: string
  court_case: number
  recaptcha: string
}

export const createCaseSubscription = (body: CaseSubscribeBody) =>
  fetchJSON(endpoints.CASE_SUBSCRIBE, {
    method: 'POST',
    body: JSON.stringify(body),
  })

export const confirmEmail = (uuid: string) =>
  fetchJSON(`${endpoints.CONFIRM_EMAIL}/${uuid}`, {
    method: 'POST',
  })

export const unsubscribe = (uuid: string, method: 'GET' | 'POST') =>
  fetchJSON(`${endpoints.UNSUBSCRIBE}/${uuid}`, {
    method,
  })
