import React, { Fragment, useContext } from 'react'
import Link from 'next/link'
import styled from 'styled-components'
import { PageContext } from 'contexts'
import { HOME } from 'constants/routes'
import { LogoMini } from 'assets/svg'
import Loader from './Loader'
import GoBack from './GoBack'

const Wrapper = styled.header`
  position: fixed;
  top: 0;
  z-index: ${({ theme }) => theme.zIndex.header};
  width: 100%;
  height: 60px;
  padding: 0 16px;
  font-family: ${({ theme }) => theme.fonts.main};
  font-size: 16px;
  line-height: 18px;
  background: #fff;
  box-shadow: 0 1px 40px rgba(0, 0, 0, 0.15);

  .inner {
    display: flex;
    align-items: center;
    height: 100%;
  }

  .logo {
    display: none;
    width: auto;
    height: 28px;
    margin-right: 32px;
  }

  .next-arrow {
    position: relative;
    top: -2px;
    display: inline-block;
    width: 8px;
    height: 8px;
    margin: 0 18px;
    border-top: 2px solid ${({ theme }) => theme.colors.grey};
    border-right: 2px solid ${({ theme }) => theme.colors.grey};
    transform: rotate(45deg);
  }

  .breadcrumbs-item {
    color: #000;
    text-decoration: none;
  }

  ${Loader} {
    top: 57px;
    height: 3px;
  }

  @media (min-width: 768px) {
    height: 80px;

    .inner {
      width: ${({ theme }) => theme.containerWidth.tablet};
      margin: 0 auto;
    }

    .logo {
      display: block;
    }

    .go-back {
      display: none;
    }

    ${Loader} {
      top: 76px;
      height: 4px;
    }
  }

  @media (min-width: 992px) {
    .breadcrumbs-item:hover {
      color: ${({ theme }) => theme.colors.primary};
    }
  }

  @media (min-width: 1200px) {
    .inner {
      width: ${({ theme }) => theme.containerWidth.desktop};
    }
  }
`

interface Nav {
  title: string
  href: string
  as: string
}

interface Props {
  title: string
  breadcrumbs: Nav[]
  goBackProps: Nav
}

const Header = ({ title, breadcrumbs, goBackProps }: Props) => {
  const { isLoading } = useContext(PageContext)

  return (
    <Wrapper>
      <div className="inner">
        <div className="go-back">
          <GoBack {...goBackProps} />
        </div>
        <Link {...HOME}>
          <LogoMini className="logo" />
        </Link>
        <div className="g-hide-on-mobile">
          {breadcrumbs.map(({ title, href, as }, index) => (
            <Fragment key={index}>
              <Link key={index} href={href} as={as}>
                <a className="breadcrumbs-item">{title}</a>
              </Link>
              <span className="next-arrow" />
            </Fragment>
          ))}
          <span>{title}</span>
        </div>
      </div>
      {isLoading && <Loader />}
    </Wrapper>
  )
}

export default Header
