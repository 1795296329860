import { css } from 'styled-components'

type ScreenType = 'desktop' | 'tablet' | 'mobile'
type Style =
  | 'margin'
  | 'margin-top'
  | 'margin-right'
  | 'margin-bottom'
  | 'margin-left'
  | 'padding'
  | 'padding-top'
  | 'padding-right'
  | 'padding-bottom'
  | 'padding-left'
  | 'top'
  | 'right'
  | 'bottom'
  | 'left'
  | 'column-gap'
type SpaceSize = '3xs' | '2xs' | 'xs' | 's' | 'm' | 'l' | 'xl' | 0

export const theme = {
  colors: {
    primary: '#f30',
    primaryHover: '#ff704c',
    primaryPressed: '#e62e00',
    accent: '#f30',
    accentHover: '#ff704c',
    accentPressed: '#e62e00',
    grey: '#989bae',
    greyBg: '#f6f5fc',
    greyLine: '#d5dbed',
    dark: '#000',
    white: '#fff',
  },
  fonts: {
    main:
      "Activist, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif",
    additional:
      "Formular, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif",
    small:
      "PT Mono, -apple-system, BlinkMacSystemFont, Roboto, Oxygen, Ubuntu, Cantarell, 'Helvetica Neue'",
  },
  zIndex: {
    header: 10,
    loader: 9,
    modal: 9999,
  },
  containerWidth: {
    mobile: '100%',
    tablet: '688px',
    desktop: '1160px',
  },
  contentWidth: {
    mobile: '100%',
    tablet: '688px',
    desktop: '960px',
  },
  space: {
    mobile: {
      '3xs': '20px', // 4
      '2xs': '16px', // 5
      xs: '28px', // 6
      s: '12px', // 8
      m: '16px', // 9
      l: '40px', // 10
      xl: '40px', // 11
    },
    tablet: {
      '3xs': '28px',
      '2xs': '24px',
      xs: '28px',
      s: '32px',
      m: '40px',
      l: '60px',
      xl: '70px',
    },
    desktop: {
      '3xs': '28px',
      '2xs': '24px',
      xs: '24px',
      s: '32px',
      m: '40px',
      l: '60px',
      xl: '70px',
    },
  },
}

const getSizes = (screen: ScreenType, ...sizes: SpaceSize[]) =>
  sizes
    .map((size: SpaceSize) => (size === 0 ? 0 : theme.space[screen][size]))
    .join(' ')

export const spacings = (style: Style, ...sizes: any) => css`
  ${style}: ${getSizes('mobile', ...sizes)};

  @media (min-width: 768px) {
    ${style}: ${getSizes('tablet', ...sizes)};
  }

  @media (min-width: 1200px) {
    ${style}: ${getSizes('desktop', ...sizes)};
  }
`

export const width = (type: 'containerWidth' | 'contentWidth') => css`
  width: ${theme[type].mobile};

  @media (min-width: 768px) {
    width: ${theme[type].tablet};
  }

  @media (min-width: 1200px) {
    width: ${theme[type].desktop};
  }
`
