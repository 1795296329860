import React, { createContext, useState } from 'react'
import { CaseData } from 'types'

interface Case {
  initSeacrhResult: boolean
  caseData: CaseData
  setCaseData: (data: CaseData) => void
  setMoreData: (newData: CaseData) => void
  resetSearchResult: () => void
}

const initialValues = {
  count: 0,
  next: '',
  previous: '',
  results: [],
}

const CaseContext = createContext<Case>({
  initSeacrhResult: false,
  caseData: initialValues,
  setCaseData: () => {},
  setMoreData: () => {},
  resetSearchResult: () => {},
})

interface Props {
  initSeacrhResult: boolean
  caseData: CaseData | null
  children: React.ReactNode
}

export const CaseProvider = ({
  initSeacrhResult,
  caseData,
  children,
}: Props) => {
  const [caseDataState, setCaseData] = useState<CaseData>(
    caseData || initialValues,
  )
  const [initSeacrhResultState, setInitSearchResult] = useState(
    initSeacrhResult,
  )

  return (
    <CaseContext.Provider
      value={{
        initSeacrhResult: initSeacrhResultState,
        caseData: caseDataState,
        setCaseData(data: CaseData) {
          setInitSearchResult(true)

          setCaseData(data)
        },
        setMoreData(newData: CaseData) {
          setCaseData({
            ...newData,
            results: [...caseDataState.results, ...newData.results],
          })
        },
        resetSearchResult() {
          setInitSearchResult(false)
        },
      }}
    >
      {children}
    </CaseContext.Provider>
  )
}

export default CaseContext
