import React from 'react'
import Link from 'next/link'
import styled from 'styled-components'
import { ArrowLeft } from 'assets/svg'

const A = styled.a`
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 18px;

  .title {
    margin-left: 8px;
    font-family: ${({ theme }) => theme.fonts.main};
  }

  @media (min-width: 768px) {
    font-size: 20px;
    line-height: 24px;
  }

  @media (min-width: 992px) {
    :hover {
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`

export interface Nav {
  title: string
  href: string
  as: string
}

const GoBack = ({ title, href, as }: Nav) => (
  <Link href={href} as={as}>
    <A>
      <ArrowLeft />
      <p className="title">{title}</p>
    </A>
  </Link>
)

export default GoBack
