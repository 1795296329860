import React from 'react'
import { useField } from 'formik'
import styled from 'styled-components'
import { Input as FbkInput } from 'fbk-components'

const StyledInput = styled(FbkInput)`
  /* @TODO это все в fbk-components. И у других инпутов тоже */
  .input {
    font-size: 18px;
    border-color: ${({ theme }) => theme.colors.greyLine};

    ::placeholder {
      font-size: 18px;
      color: ${({ theme }) => theme.colors.grey};
    }

    :-webkit-autofill {
      box-shadow: rgb(255, 255, 255) 0 0 0 50px inset;
    }
  }

  .input-error {
    border-color: ${({ theme }) => theme.colors.primary};
  }

  .label {
    font-size: 15px;
  }
`

interface Props {
  name: string
  label?: string
  allowedСharacters?: RegExp
  disableDecimals?: boolean
  maxLength?: number
  placeholder?: string
  type?: string
}

const Input = ({
  name,
  allowedСharacters,
  disableDecimals,
  ...rest
}: Props) => {
  const [field, meta] = useField({ name })

  return (
    <StyledInput
      styleType="lightGrey"
      isBig
      disableDecimals={disableDecimals}
      error={meta.touched && meta.error}
      allowedСharacters={allowedСharacters}
      {...field}
      {...rest}
    />
  )
}

export default Input
