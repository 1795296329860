import React from 'react'
import Link from 'next/link'
import styled from 'styled-components'
import { formatPublicDate } from 'fbk-utils'
import { ArrowRight } from 'assets/svg'
import { spacings } from 'theme'
import { NewsItem as INewsItem } from 'types'
import { NEWS_ARTICLE } from 'constants/routes'

interface Props extends INewsItem {
  className?: string
}

const NewsItem = ({ className, id, heading, description, meta }: Props) => {
  const { date, year } = formatPublicDate(meta.published_at)

  return (
    <Link {...NEWS_ARTICLE(id)}>
      <a className={className}>
        <div className="content">
          <h4 className="title" dangerouslySetInnerHTML={{ __html: heading }} />
          <p className="g-light-text">
            {date} {year}
          </p>
          <div
            className="text"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </div>
        <p className="more g-big-text">
          Подробнее <ArrowRight className="icon" />
        </p>
      </a>
    </Link>
  )
}

const StyledNewsItem = styled(NewsItem)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  max-height: 480px;
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.colors.greyLine};
  transition: 0.2s ease;
  ${spacings('padding', '2xs')};

  :not(:last-child) {
    ${spacings('margin-bottom', 'm')};
  }

  .content {
    position: relative;
    display: flex;
    flex: 1;
    flex-flow: column;
    padding-bottom: 32px;
    overflow: hidden;

    ::after {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      height: 32px;
      content: '';
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 100%);
    }
  }

  .title,
  .g-light-text {
    margin-bottom: 16px;
  }

  .more {
    display: flex;
    align-items: center;
    margin-top: 14px;
    color: ${({ theme }) => theme.colors.primary};

    .icon {
      width: 22px;
      height: 16px;
      margin-left: 8px;
    }
  }

  @media (min-width: 768px) {
    width: calc(50% - 20px);
    max-width: 560px;
    height: 480px;
    max-height: auto;

    :nth-last-child(-n + 2) {
      margin-bottom: 0;
    }
  }

  @media (min-width: 992px) {
    .more:hover {
      filter: brightness(60%);
    }
  }

  @media (min-width: 1200px) {
    height: 376px;
  }
`

export default StyledNewsItem
