import getConfig from 'next/config'

const { publicRuntimeConfig = {} } = getConfig()

export const NODE_ENV = publicRuntimeConfig.NODE_ENV
export const API_URL = process.env.API_URL || '/api/v1'
export const GA_TRACKING_ID = process.env.GA_TRACKING_ID || ''
export const USE_AUTHORIZATION: boolean =
  publicRuntimeConfig.USE_AUTHORIZATION === 'true'
export const BASE_AUTHORIZATION = process.env.BASE_AUTHORIZATION || ''
export const SENTRY_DSN = process.env.SENTRY_DSN || ''
