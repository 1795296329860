import React, { useState, createContext, ReactNode } from 'react'
import { SearchFormValues } from 'types'

interface SearchForm {
  initialValues: SearchFormValues
  resetForm: () => void
  countReset: number
}

const initialValuesDefault = {
  person: '',
  representative: '',
  number: '',
  year: '',
}

const SearchFormContext = createContext<SearchForm>({
  initialValues: initialValuesDefault,
  resetForm: () => {},
  countReset: 0,
})

interface Props {
  children: ReactNode
  initialValues: SearchFormValues
}

export const SearchFormProvider = ({ initialValues, children }: Props) => {
  const [countReset, setCountReset] = useState(0)
  const [initialValuesState, setInitialValues] = useState(initialValues)

  return (
    <SearchFormContext.Provider
      value={{
        initialValues: initialValuesState,
        resetForm() {
          setCountReset(value => value + 1)
          setInitialValues(initialValuesDefault)
        },
        countReset,
      }}
    >
      {children}
    </SearchFormContext.Provider>
  )
}

export default SearchFormContext
