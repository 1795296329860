import React, { useState, useCallback, useEffect } from 'react'
import styled from 'styled-components'
import { Spinner } from 'fbk-components'
import { CaseSubscribeForm } from 'components'
import { theme } from 'theme'
import { CaseItem, Event as IEvent } from 'types'
import { formatPublicDate } from 'fbk-utils'
import useScreenSize from 'hooks/use-screen-size'
import { formatLocalDateToUTC } from 'utils/helpers'
import { getCaseEvents } from 'api'
import Event from './Event'
import Status from './Status'
import CustomInput from './CustomInput'

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  padding-top: 40px;

  :not(:last-child) {
    padding-bottom: 40px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.greyLine};
  }

  .main {
    width: 100%;
  }

  .left,
  .events {
    max-width: 720px;
  }

  .title {
    margin-bottom: 20px;
  }

  .events-btn {
    display: flex;
    align-items: center;
    height: 26px;
    margin-top: 32px;
    font-family: ${({ theme }) => theme.fonts.additional};
    text-decoration: underline;
  }

  .events-title {
    margin-top: 32px;
  }

  .spinner {
    margin-left: 12px;
  }

  .subscribe-form {
    margin-top: 60px;
  }

  @media (max-width: 767px) {
    .info-title {
      display: block;
      margin-top: 12px;
    }
  }

  @media (min-width: 1200px) {
    .main {
      display: flex;
      justify-content: space-between;
    }

    .left-content {
      width: 700px;
    }

    .events-btn {
      margin-top: 20px;
    }

    .events-title {
      margin-top: 20px;
    }
  }
`

interface Props extends CaseItem {
  isOnlyOne: boolean
  isCasePage?: boolean
}

const Case = ({
  id,
  intro_date,
  finished,
  number,
  person,
  representative,
  status,
  events,
  isOnlyOne,
  isCasePage,
}: Props) => {
  const [eventsAreOpen, setEventsShow] = useState(false)
  const [eventsData, setEventsData] = useState(events)

  useEffect(() => {
    setEventsData(events)
    setEventsShow(false)
  }, [events])

  const [isLoading, setLoading] = useState(false)

  const { isLargeScreen } = useScreenSize()

  const renderInfo = useCallback(
    (title: string, value: string) => (
      <p>
        <span className="g-light-text info-title">{title}:</span>{' '}
        {value && value !== 'n/a' ? value : 'Нет данных'}
      </p>
    ),
    [],
  )

  const handleShowEvents = useCallback(async () => {
    if (eventsAreOpen) {
      return setEventsShow(false)
    }

    if (!eventsData.length) {
      try {
        setLoading(true)

        const data = await getCaseEvents(id)

        setEventsData(data)
        setEventsShow(true)
      } catch (err) {
        console.error(err)
      } finally {
        setLoading(false)
      }
    } else {
      setEventsShow(true)
    }
  }, [eventsAreOpen, eventsData.length, id])

  const { date, year } = formatPublicDate(formatLocalDateToUTC(intro_date), {
    isFull: true,
    hideСurrentYear: false,
  })

  const statusProps = {
    id,
    finished,
    isOnlyOne,
    ...status,
  }

  return (
    <Wrapper>
      <div className="main">
        <div className="left-content">
          <div className="left">
            {React.createElement(
              isCasePage ? 'h2' : 'h3',
              {
                className: 'title',
              },
              person,
            )}
            {renderInfo('Номер дела', number)}
            {renderInfo('Дата обращения', `${date} ${year}`)}
            {renderInfo('Адвокат', representative)}
          </div>
          {!isLargeScreen && <Status {...statusProps} />}
          {(eventsAreOpen || isOnlyOne) && (
            <div className="events">
              <p className="g-light-text events-title">Последние события:</p>
              {eventsData.map((event: IEvent, index: number) => (
                <Event key={index} {...event} />
              ))}
            </div>
          )}
          {!isOnlyOne && (
            <a
              role="button"
              className="events-btn g-red-text"
              onClick={handleShowEvents}
            >
              {eventsAreOpen ? 'Скрыть события' : 'Показать события'}
              {isLoading && (
                <Spinner
                  color={theme.colors.primary}
                  width={5}
                  className="spinner"
                />
              )}
            </a>
          )}
          {isOnlyOne && !isCasePage && !finished && (
            <CaseSubscribeForm id={id} className="subscribe-form">
              <CustomInput />
            </CaseSubscribeForm>
          )}
        </div>
        {isLargeScreen && <Status {...statusProps} />}
      </div>
    </Wrapper>
  )
}

export default Case
