import React, { useState, useContext } from 'react'
import styled from 'styled-components'
import { Formik, Form, FormikProps } from 'formik'
import { emailValidate } from 'fbk-utils'
import { Input, Button, Modal } from 'components'
import { Bell } from 'assets/svg'
import { createCaseSubscription } from 'api'
import { parseApiErrors } from 'utils/helpers'
import { SiteContentContext } from 'contexts'

interface Props {
  id: number
  className?: string
  onSuccess?: (response: { title: string; text: string }) => void
  children?: React.ReactElement
}

interface InitialValues {
  email: string
}

const INITIAL_VALUES: InitialValues = {
  email: '',
}

const CaseSubscribeForm = ({ id, children, onSuccess, className }: Props) => {
  const [isLoading, setloading] = useState(false)
  const [submitError, setError] = useState('')
  const [successText, setSuccessText] = useState('')
  const [modalIsOpen, setModal] = useState(false)

  const { recaptcha_site_key } = useContext(SiteContentContext)

  const handleSubmit = async ({ email }: InitialValues, actions: any) => {
    try {
      setloading(true)
      setError('')

      const token = await window.grecaptcha.execute(recaptcha_site_key, {
        action: 'caseSubscribe',
      })

      const response = await createCaseSubscription({
        email: email.trim(),
        court_case: id,
        recaptcha: token,
      })

      if (onSuccess) {
        onSuccess(response)
      } else {
        setSuccessText(response.text)
        setModal(true)
      }

      actions.resetForm()
    } catch (err) {
      console.error(err)

      setError(parseApiErrors(err))
    } finally {
      setloading(false)
    }
  }

  const handleValidate = ({ email }: InitialValues) => {
    const errors: { email?: string } = {}

    const {
      errorMessage,
      params: { validError },
    } = emailValidate(email)

    if (errorMessage && validError) {
      errors.email = errorMessage
    } else {
      delete errors.email
    }

    return errors
  }

  return (
    <>
      <Formik
        validateOnBlur={false}
        initialValues={INITIAL_VALUES}
        onSubmit={handleSubmit}
        validate={handleValidate}
      >
        {(props: FormikProps<InitialValues>) => {
          return (
            <Form className={className}>
              {children ? (
                React.cloneElement(children, { isLoading })
              ) : (
                <>
                  <Input name="email" label="Ваша электронная почта" />
                  <Button
                    type="submit"
                    color="accent"
                    className="btn"
                    isLoader={isLoading}
                    loaderText="Подождите..."
                  >
                    <Bell className="bell-icon" />
                    Подписаться
                  </Button>
                </>
              )}
              {submitError && (
                <div className="error-wrapper">
                  <p className="g-error-text">{submitError}</p>
                </div>
              )}
            </Form>
          )
        }}
      </Formik>
      <Modal
        title={
          <>
            <span className="g-red-text">Подтвердите почту,</span> чтобы
            получать обновления по&nbsp;делу
          </>
        }
        isOpen={modalIsOpen}
        onClose={() => setModal(false)}
      >
        <div className="success-content">
          <div dangerouslySetInnerHTML={{ __html: successText }} />
          <Button isLight onClick={() => setModal(false)}>
            Закрыть
          </Button>
        </div>
      </Modal>
    </>
  )
}

const StyledCaseSubscribeForm = styled(CaseSubscribeForm)`
  .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 32px;
  }

  .bell-icon {
    margin-right: 8px;
    color: #fff;
  }

  .g-error-text {
    margin-top: 8px;
  }

  @media (min-width: 768px) {
    .btn {
      width: 100%;
    }
  }
`

export default StyledCaseSubscribeForm
