import React from 'react'
import styled from 'styled-components'

const Loader = ({ className }: any) => {
  if (!process.browser) return null

  return (
    <div className={className}>
      <div className="bar" />
    </div>
  )
}

const StyledLoader = styled(Loader)`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: ${({ theme }) => theme.zIndex.loader};
  height: 8px;

  @keyframes move_eye {
    from {
      width: 0;
    }

    to {
      width: 100%;
    }
  }

  .bar {
    height: 100%;
    background: ${({ theme }) => theme.colors.primary};
    animation: 1s linear infinite move_eye;
  }
`

export default StyledLoader
